import { Box, styled, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import complete from "../images/complete.png";
import incomplete1 from "../images/incomplete1.png";
import incomplete2 from "../images/incomplete2.png";
import incomplete3 from "../images/incomplete3.png";

const TicketStyle = styled("div")`
  background: white;
  border-radius: 10px;
`;

const TopStyle = styled("div")`
  border-bottom: 1px dashed black;
  padding: 16px 16px 20px 16px;
  position: relative;

  &:before,
  &:after {
    content: "";
    width: 32px;
    height: 36px;
    border-radius: 50%;
    position: absolute;
    bottom: -18px;
    background: black;
  }
  &:before {
    left: -16px;
  }
  &:after {
    right: -16px;
  }
`;

const BottomStyle = styled("div")`
  padding: 24px 24px 16px 24px;
`;

const FlipStyle = styled("div")`
  cursor: pointer;
  background: #ffffff;
  perspective: 1000px;

  &.flip .inner {
    transform: rotateY(180deg);
  }

  .inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }

  .front,
  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .front {
    background-color: #bbb;
    color: black;
  }

  .back {
    background-color: #2980b9;
    color: white;
    transform: rotateY(180deg);

    img {
      position: absolute;

      &.complete {
        top: 0;
        left: 0;
        width: 100%;
      }
      &.s1 {
        top: 0;
        left: 0;
        width: 50%;
      }
      &.s2 {
        top: 0;
        right: 0;
        width: 50%;
      }
      &.s3 {
        bottom: 0;
        left: 0;
        height: 50%;
      }
    }
  }
`;

const TraitItem = ({ t }) => (
  <Typography
    key={t.title}
    variant="caption"
    sx={{ color: "#666" }}
    fontSize="0.875rem"
    mb={1}
    className="d-flex align-baseline justify-space-between"
  >
    {t.trait_type}
    <Typography
      fontWeight={600}
      variant="body2"
      className="d-block"
      sx={{ color: "#000", mt: "-2px" }}
    >
      {t.value}
    </Typography>
  </Typography>
);

const Topic = ({ children }) => (
  <Typography variant="body2" fontWeight={600} sx={{ color: "#000" }} mb={1}>
    {children}
  </Typography>
);

const staticType = ["Background", "Moon", "Plate", "Tail", "Whale"];
const NFTTicket = ({ id, title, caption, img, attributes = [], status }) => {
  const [flip, setFlip] = useState(false);
  const staticAttributes = useMemo(() => {
    return attributes.filter(at => staticType.includes(at.trait_type));
  }, [attributes]);

  const dynamicAttributes = useMemo(() => {
    return attributes.filter(at => !staticType.includes(at.trait_type));
  }, [attributes]);

  return (
    <TicketStyle>
      <TopStyle>
        <FlipStyle
          className={`${flip ? "flip" : ""} ratio1-1`}
          onClick={() => {
            setFlip(!flip);
          }}
        >
          <div className="inner">
            <div className="front">
              <img src={img} alt="" className="d-block" width="100%" />
            </div>
            <div className="back">
              <img
                src={complete}
                alt=""
                className="d-block complete"
                width="100%"
              />

              {!status[0] && (
                <img
                  src={incomplete1}
                  alt=""
                  className="d-block s1"
                  width="100%"
                />
              )}

              {!status[1] && (
                <img
                  src={incomplete2}
                  alt=""
                  className="d-block s2"
                  width="100%"
                />
              )}

              {!status[2] && (
                <img
                  src={incomplete3}
                  alt=""
                  className="d-block s3"
                  width="100%"
                />
              )}
            </div>
          </div>
        </FlipStyle>

        <Typography
          fontWeight={600}
          sx={{ color: "#000" }}
          align="center"
          mt={2}
        >
          {title}
        </Typography>
      </TopStyle>
      <BottomStyle>
        <Topic>Description</Topic>
        <Typography variant="body2" sx={{ color: "#000" }}>
          {caption}
        </Typography>

        <Box pt={2} mt={2} sx={{ borderTop: "1px solid rgba(0,0,0,0.1)" }}>
          <Topic>Properties</Topic>
          {staticAttributes.map(t => (
            <TraitItem t={t} />
          ))}
          <Topic>Dynamic Attributes</Topic>
          {dynamicAttributes.map(t => (
            <TraitItem t={t} />
          ))}
        </Box>
      </BottomStyle>
    </TicketStyle>
  );
};

export default NFTTicket;
