import { Box, Skeleton, styled } from "@mui/material";
import React from "react";

const BoxStyle = styled("div")`
  background: linear-gradient(
    111.71deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(0, 0, 0.9) 49.59%
  );
  boxshadow: 0px 1px 24px -1px rgba(0, 0, 0, 0.1);
  backdropfilter: blur(20px);
  padding: 16px 16px 20px 16px;
  position: relative;
  margin-top: -1px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border: 1px solid transparent;
    background: linear-gradient(
        112.68deg,
        rgba(255, 255, 255, 0.5) 5.14%,
        rgba(255, 255, 255, 0) 49.32%,
        rgba(255, 255, 255, 0.5) 97.34%
      )
      border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

const NFTCardSkeleton = () => {
  return (
    <Box borderRadius="1rem" overflow="hidden">
      <div className="ratio1-1" style={{ background: "#000" }}>
        <Skeleton
          animation="wave"
          height="100%"
          width="100%"
          sx={{ transform: "none" }}
        />
      </div>

      <BoxStyle>
        <div className="d-flex justify-space-between align-center">
          <Skeleton
            variant="text"
            animation="wave"
            width="90%"
            sx={{ fontSize: "1.25rem", mb: 0.5 }}
          />

          {/* <Chip variant="filled" size="small" label={`${price} ETH`} /> */}
        </div>
        <Skeleton
          variant="text"
          animation="wave"
          width="100%"
          sx={{ fontSize: "0.875rem", mb: "2px" }}
        />
        <Skeleton
          variant="text"
          animation="wave"
          width="60%"
          sx={{ fontSize: "0.875rem" }}
        />
      </BoxStyle>
    </Box>
  );
};

export default NFTCardSkeleton;
