import { Box, styled, Typography } from "@mui/material";
import React from "react";

const BoxStyle = styled("div")`
  background: linear-gradient(
    111.71deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(0, 0, 0.9) 49.59%
  );
  boxshadow: 0px 1px 24px -1px rgba(0, 0, 0, 0.1);
  backdropfilter: blur(20px);
  padding: 16px 16px 20px 16px;
  position: relative;
  margin-top: -1px;
  min-height: 108px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border: 1px solid transparent;
    background: linear-gradient(
        112.68deg,
        rgba(255, 255, 255, 0.5) 5.14%,
        rgba(255, 255, 255, 0) 49.32%,
        rgba(255, 255, 255, 0.5) 97.34%
      )
      border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

const NFTCard = ({ img, title, caption, price, ...props }) => {
  return (
    <Box
      borderRadius="1rem"
      overflow="hidden"
      sx={{ cursor: "pointer" }}
      {...props}
    >
      <img src={img} alt="" className="d-block" width="100%" />

      <BoxStyle>
        <div className="d-flex justify-space-between align-center">
          <Typography className="ellipsis" fontWeight={600}>
            {title}
          </Typography>
          {/* <Chip variant="filled" size="small" label={`${price} ETH`} /> */}
        </div>
        <Typography
          className="ellipsis-2"
          variant="body2"
          sx={{ minHeight: "40px" }}
        >
          {caption}
        </Typography>
      </BoxStyle>
    </Box>
  );
};

export default NFTCard;
