import {
  Container,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import six from "../images/six-network.png";

const FooterStyle = styled("footer")`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  width: 100%;
`;

const Footer = () => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <div style={{ height: "62px" }} />
      <FooterStyle className="bg-gradient">
        <Container
          maxWidth="lg"
          sx={{ py: 1.5, flexDirection: { xs: "row", sm: "row" }, px: 2 }}
          className="d-flex justify-space-between align-center"
        >
          <div className="d-flex align-center">
            <Typography variant={smUp ? "body2" : "caption"}>
              Powered by
            </Typography>
            <img
              src={six}
              alt="SIX Network"
              height={smUp ? 48 : 40}
              style={{ marginTop: "-8px", marginLeft: "4px" }}
            />
          </div>
          <Typography variant="caption" align="right" whiteSpace="pre-line">
            {`Copyright © 2022 SIX Network.${!smUp && `\n`}All Right Reserved`}
          </Typography>
        </Container>
      </FooterStyle>
    </>
  );
};

export default Footer;
