import {
  CheckCircleOutlineRounded,
  CheckCircleRounded,
} from "@mui/icons-material";
import { styled, Typography } from "@mui/material";
import React from "react";
import gift from "../images/gift.png";

import pin from "../images/pin.png";

const RowStyle = styled("div")`
  min-height: 75px;
  position: relative;
  padding: 16px 0 16px 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IconStyle = styled("div")`
  width: 40px;
  height: 40px;
  background: linear-gradient(90deg, #04b1fb 0%, #465ef0 100%);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: calc(100% + 4px);
  transform: translateY(-50%);

  img {
    height: 20px;
  }
`;

const BoxStyle = styled("div")`
  position: relative;
  background: linear-gradient(
    111.71deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 49.59%
  );
  box-shadow: 0px 1px 24px -1px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  border-radius: 12px;
  padding: 24px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: 12px;
    border: 1px solid transparent;
    background: linear-gradient(
        112.68deg,
        rgba(255, 255, 255, 0.5) 5.14%,
        rgba(255, 255, 255, 0) 49.32%,
        rgba(255, 255, 255, 0.5) 97.34%
      )
      border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

const CheckIcon = ({ checked, className }) => {
  return checked ? (
    <CheckCircleRounded color="primary" className={className} />
  ) : (
    <CheckCircleOutlineRounded className={className} />
  );
};

// const PremiumItem = ({ title, img, point, checked }) => {
//   return (
//     <div className="d-flex flex-column align-center col-6 pb-5">
//       <img src={img} alt="" height={56} style={{ maxWidth: "100px" }} />
//       <Typography variant="body2" align="center" fontWeight={700} mt={1}>
//         {title}
//       </Typography>
//       <Typography
//         variant="body2"
//         align="center"
//         fontWeight={700}
//         color="primary"
//         display="flex"
//         alignItems="baseline"
//         justifyContent="center"
//       >
//         {point}

//         <Typography variant="body2" color="text.primary" ml={1}>
//           Points
//         </Typography>
//       </Typography>

//       <CheckIcon checked={checked} className="mt-1" />
//     </div>
//   );
// };

const StatusCard = ({ points, status }) => {
  // const premiumItems = [
  //   { title: "T-Shirt", point: "1,000", img: tShirt, checked: status.tShirt },
  //   { title: "Bottle", point: "600", img: bottle, checked: status.bottle },
  //   { title: "Cap", point: "500", img: cap, checked: status.cap },
  //   { title: "Opener", point: "200", img: opener, checked: status.opener },
  // ];

  return (
    <BoxStyle>
      <div className="bd-b pb-4">
        <Typography variant="h4" align="center" lineHeight={1.2}>
          {points}
        </Typography>
        <Typography align="center">Points</Typography>
      </div>

      <RowStyle>
        <IconStyle>
          <img src={pin} alt="" />
        </IconStyle>
        <div className="pr-4">
          <Typography variant="body2">SIX Network Booth</Typography>
          <Typography variant="body2" fontWeight={700}>
            Check-in
          </Typography>
        </div>
        <CheckIcon checked={status.checkIn} />
      </RowStyle>

      <RowStyle>
        <IconStyle>
          <img src={gift} alt="" />
        </IconStyle>
        <Typography variant="body2" fontWeight={700} className="pr-4">
          Redeemed
        </Typography>
        <CheckIcon checked={status.redeemed} />
      </RowStyle>

      <div className="d-flex pt-4">
        <Typography
          variant="body2"
          align="center"
          textTransform="uppercase"
          className="bd-r d-flex flex-column align-center"
          fontWeight={700}
          flexGrow={1}
        >
          BONUS 1
          <CheckIcon checked={status.bonus1} className="mt-1" />
        </Typography>
        <Typography
          variant="body2"
          align="center"
          textTransform="uppercase"
          className="d-flex flex-column align-center"
          fontWeight={700}
          flexGrow={1}
        >
          BONUS 2
          <CheckIcon checked={status.bonus2} className="mt-1" />
        </Typography>
      </div>

      {/* <div className="pt-4 pb-1 bd-b">
        <Typography variant="body2" align="center" mb={2}>
          SIX Protocol Premium
        </Typography>

        <div className="d-flex flex-wrap">
          {premiumItems.map((p) => (
            <PremiumItem
              key={p.title}
              title={p.title}
              point={p.point}
              img={p.img}
              checked={p.checked}
            />
          ))}
        </div>
      </div>

      <div className="py-4">
        <Typography variant="body2" align="center">
          Premium Redeem limit for 1 item per tier only. Premium Redeem limit
          for 1 item per tier only. Premium Redeem limit for 1 item per tier
          only. Premium Redeem limit for 1 item per tier only. Premium Redeem
          limit for 1 item per tier only.
        </Typography>
      </div> */}
    </BoxStyle>
  );
};

export default StatusCard;
