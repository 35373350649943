import PlaceIcon from "@mui/icons-material/Place";
import {
  Box,
  Button,
  styled,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Section from "../components/Section";
import animate from "../images/animate.gif";
import c1 from "../images/c1.png";
import c2 from "../images/c2.png";
import c3 from "../images/c3.png";
import nft2 from "../images/nft-gen2.png";
import s2 from "../images/s2.png";
import s3 from "../images/s3.png";
import sixNoText from "../images/six-protocol-no-text.png";
import polygon from "../images/six-protocol-polygon.png";
import sixV from "../images/six-protocol-v.png";
import six from "../images/six-protocol.png";

const Item = ({ title, img, variant = "body1", ...props }) => {
  return (
    <Box className="d-flex flex-column align-center" {...props}>
      <img src={img} alt="" width={120} />
      <Typography
        sx={{ color: "#666666", whiteSpace: "pre-wrap" }}
        mt={1}
        align="center"
        textTransform="uppercase"
        variant={variant}
      >
        {title}
      </Typography>
    </Box>
  );
};

const RightAnimate = styled("img")`
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 65%;
  right: -10%;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    display: block !important;
  }
`;

const Home = () => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const listItem = [
    { title: `Token Autonomy\nManagement`, img: c1 },
    { title: `Web 3.0\nIntegration`, img: c2 },
    { title: `Business\nTokenization`, img: c3 },
  ];

  return (
    <>
      <Section
        fullScreen
        grdYTop
        grdYBottom
        sx={{
          display: "flex",
          flexDirection: "column",

          "& .MuiContainer-root": {
            my: "auto",
            position: "relative",
          },
        }}
      >
        <RightAnimate src={animate} alt="" />

        <Toolbar sx={{ height: "64px" }} />
        <Box
          display="flex"
          alignItems="center"
          justifyContent={{ xs: "flex-start", sm: "space-between" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Box sx={{ width: { xs: "100%", sm: "60%" }, mb: { xs: 6, sm: 0 } }}>
            <img
              src={nft2}
              alt=""
              className={`d-block ${smUp ? "mb-8" : "mb-6"}`}
              width="80%"
            />

            <Typography fontSize={{ xs: "1rem", sm: "1.25rem" }} mb={4}>
              Special Collection of NFT Gen 2, “Whale Gate”
            </Typography>

            <Button
              variant="contained"
              component={Link}
              to="/nfts"
              sx={{ width: "240px" }}
            >
              Explore Collection
            </Button>
          </Box>

          <Box
            sx={{
              display: { xs: "flex", sm: "none" },
              justifyContent: "center",
            }}
          >
            <img
              src={animate}
              alt=""
              className="d-block"
              style={{ width: "120%" }}
            />
          </Box>
        </Box>
      </Section>

      <Section className="pb-0">
        <Typography variant="h3" textTransform="uppercase">
          Integrate NFT on any business models with SIX Protocol’s NFT Gen2
        </Typography>
        <Typography
          variant="h3"
          textTransform="uppercase"
          fontWeight={200}
          mb={3}
        >
          : Beyond NFTs
        </Typography>
        <Typography mb={{ xs: 5, md: 10 }} whiteSpace="pre-line">
          {`NFT Gen2 allows the business to store customer privilege and campaign data on the NFT itself via dynamic data layer that runs on the SIX Protocol chain. The NFT Gen2 comes with an ability to track and trace any NFT usage.`}
        </Typography>

        <Typography
          variant="h3"
          className="color-gradient"
          align="center"
          mb={{ xs: 4, md: 6 }}
        >
          NFT Verification / Payment
        </Typography>

        <img
          src={s2}
          alt=""
          className="d-block"
          style={{ width: "calc(100% + 48px)", marginLeft: "-24px" }}
        />
      </Section>

      <Section grdTop>
        <Typography
          variant="h3"
          textTransform="uppercase"
          align="center"
          mb={{ xs: 4, md: 8 }}
        >
          Unique Use of Dynamic Metadata
        </Typography>

        <Box
          display="grid"
          gridTemplateColumns={{ xs: "1fr", sm: "1fr 1fr" }}
          columnGap={10}
          rowGap={{ xs: 3, md: 7 }}
        >
          <div>
            <Typography textTransform="capitalize" fontWeight={600}>
              Marking Usage Value
            </Typography>
            <Typography>
              The business can embed the use case and redeemable inventory for
              the users to obtain their rights.
            </Typography>
          </div>

          <div>
            <Typography textTransform="capitalize" fontWeight={600}>
              Point System
            </Typography>
            <Typography>
              Dynamic Metadata allows the NFT to collect the point and work as a
              membership or CRM tool for the users and brands.
            </Typography>
          </div>

          <div>
            <Typography textTransform="capitalize" fontWeight={600}>
              Proof of Attendance
            </Typography>
            <Typography>
              Users can use the NFT as a Proof of Attendance concept as a
              fraud-free ticket to attend the events or complete the quest and
              journey.
            </Typography>
          </div>

          <div>
            <Typography textTransform="capitalize" fontWeight={600}>
              Mergeable Attribute
            </Typography>
            <Typography>
              Once the users have completed their task or quest, the attribute
              of NFT can be merged with new attributes.
            </Typography>
          </div>

          <div>
            <Typography textTransform="capitalize" fontWeight={600}>
              Transformable NFT
            </Typography>
            <Typography>
              Dynamic Metadata allows the collection owner to change the artwork
              on NFT. The business can create a business case related to artwork
              transformation and its attributes to create uniqueness as a symbol
              of use.
            </Typography>
          </div>

          <div>
            <Typography textTransform="capitalize" fontWeight={600}>
              Delegating Gas Fee
            </Typography>
            <Typography>
              Via fee delegation address, users can process any NFT transaction
              without having to prepare for a gas fee which will be provided by
              either the business or the collection owner.
            </Typography>
          </div>
        </Box>

        <img
          src={s3}
          alt=""
          className="d-block"
          style={{ width: "calc(100% + 48px)", marginLeft: "-24px" }}
        />
      </Section>

      <Section
        sx={{ height: "52px", padding: "0 !important" }}
        className="bg-gradient"
      />

      <Section sx={{ background: { xs: "#E5E5E5", md: "#F7FAFC" } }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box
            pr={{ md: 17 }}
            display="flex"
            flexDirection="column"
            alignItems={{ xs: "center", md: "flex-start" }}
          >
            <img
              src={mdUp ? six : polygon}
              alt=""
              className="d-block"
              height={mdUp ? 120 : 160}
            />

            {/* {!mdUp && (
              <Box mt={4} className="d-flex flex-column align-center">
                {listItem.map((l) => (
                  <Item
                    key={l.title}
                    title={l.title}
                    img={l.img}
                    my={2}
                    variant="body2"
                  />
                ))}
              </Box>
            )} */}

            <Typography
              sx={{ color: "#414040" }}
              fontWeight="600"
              mb={2}
              mt={5}
            >
              “Limitless boundary of utilities”
            </Typography>
            <Typography sx={{ color: "#414040" }} mb={5}>
              SIX Protocol is a blockchain infrastructure layer built to help
              businesses migrate their assets into the blockchain by issuing
              their tokens, including cryptocurrency and NFT, to the destination
              chain.
            </Typography>

            <Button
              variant="contained"
              href="https://sixprotocol.com/"
              target="_blank"
            >
              Learn More
            </Button>
          </Box>
          {mdUp && (
            <div>
              <img src={sixNoText} alt="" className="d-block" height={400} />
            </div>
          )}
        </Box>

        {/* {mdUp && (
          <Box display="flex" justifyContent="center" mt={9}>
            {listItem.map((l) => (
              <Item key={l.title} title={l.title} img={l.img} mx={6} />
            ))}
          </Box>
        )} */}
      </Section>

      <Section py={6} grdBottom>
        <Box
          display="flex"
          alignItems="center"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
        >
          <div className={!smUp ? "mb-8" : ""}>
            <img
              src={sixV}
              alt=""
              className="d-block"
              height={mdUp ? 160 : 120}
            />
          </div>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flexDirection={{ xs: "row-reverse", sm: "row" }}
          >
            <Typography
              variant="body2"
              sx={{ textAlign: { xs: "left", sm: "right" } }}
              lineHeight={1.7}
            >
              380 Jalan Besar #08-02, ARC380, Singapore
            </Typography>
            <PlaceIcon
              sx={{ fontSize: "40px", ml: { sm: 3 }, mr: { xs: 3, sm: 0 } }}
            />
          </Box>
        </Box>
      </Section>
    </>
  );
};

export default Home;
