import Multicall from "@dopex-io/web3-multicall";
import { Box, InputAdornment, OutlinedInput, Toolbar } from "@mui/material";
import axios from "axios";
import get from "lodash.get";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Web3 from "web3";
import InfiniteScroll from "react-infinite-scroll-component";
import abi from "../abis/nfts.json";
import Drawer from "../components/Drawer";
import NFTCard from "../components/NFTCard";
import NFTCardSkeleton from "../components/NFTCardSkeleton";
import Section from "../components/Section";
import searchImg from "../images/search.png";

const web3 = new Web3(process.env.REACT_APP_RPC_URL);
const contract = new web3.eth.Contract(
  abi.output.abi,
  process.env.REACT_APP_NFT_ADDRESS
);
const multicall = new Multicall({
  multicallAddress: process.env.REACT_APP_MULTICALL_ADDRESS,
  provider: process.env.REACT_APP_RPC_URL
  // defaultBlock: 1000 /* Optional */
});

let startFetch = false

const Explore = () => {
  const { nftId } = useParams();
  const [openModal, setOpenModal] = useState(nftId ? { id: nftId } : undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [totalSupplyCount, setTotalSupplyCount] = useState(1);
  const [nfts, setNfts] = useState([]);
  const [allUris, setAllUris] = useState([]);
  const [currentFetch, setCurrentFetch] = useState(0);

  useEffect(() => {
    const fetch = async () => {
      const totalSupply = await contract.methods.totalSupply().call();
      // console.log('totalSupply', totalSupply)
      const aggregate = [...Array(parseInt(totalSupply))].map((x, index) =>
        contract.methods.tokenURI(web3.utils.toBN(index + 1))
      );
      const uris = await multicall.aggregate(aggregate);
      if (nftId) {
        const url = uris[nftId - 1]
        const data = await axios.get(url)
        const responseData = data.data
        setOpenModal({ data: responseData, id: nftId });
      }
      setAllUris(uris);
      setTotalSupplyCount(totalSupply);
    };
    fetch();
  }, []);
  // contract.methods.totalSupply().call().then(result => { console.log(result) })

  useEffect(() => {
    if (allUris.length > 0 && startFetch === false) {
      fetchNext(0)
      startFetch = true
    }
  }, [allUris])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const searchFunc = async () => {
        setIsLoading(true)
        setCurrentFetch(0)
        setNfts([])
        const url = allUris[parseInt(search) - 1]
        const data = await axios.get(url)
        const responseData = data.data
        setOpenModal({ data: responseData, id: nftId });
        setNfts([responseData])
        setIsLoading(false)

      }
      console.log(search)
      if (search.length > 0 && search.match(/^\d+$/)) {
        searchFunc()
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const fetchNext = useCallback(async (inputFetch) => {
    
    let toFetch = 0
    if (inputFetch) {
      toFetch = inputFetch
    } else {
      toFetch = currentFetch
    }
    const uris = allUris.slice(toFetch, toFetch + 20);
    const builtQueries = uris.map(uri => axios.get(uri));
    const allNfts = await Promise.all(builtQueries);
    const allData = allNfts.map(x => x.data);
    const newData = [...nfts, ...allData]
    setNfts(newData);
    setIsLoading(false);
    setCurrentFetch(newData.length)
  }, [allUris, currentFetch]);

  const getList = useMemo(() => {
    return nfts.map((x, index) => {
      const element = (
        <NFTCard
          img={x.image}
          title={x.name}
          caption={x.description}
          // price="0.001"
          onClick={() => {
            setOpenModal({ data: x, id: index + 1 });
            window.history.replaceState(null, x.name, "/nfts/" + (index + 1));
          }}
        />
      );
      return element
      // if (search !== "" && search.length > 0) {
      //   return parseInt(search) === index + 1 ? element : null;
      // } else {
      //   return element;
      // }
    });
    //   search && search.length > 0 && search !== "" ? (
    //     parseInt(search) === index + 1 ? (
    //       <NFTCard
    //         img={x.image}
    //         title={x.name}
    //         caption={x.description}
    //         // price="0.001"
    //         onClick={() => {
    //           setOpenModal({ data: x, id: index + 1 });
    //           window.history.replaceState(null, x.name, "/nfts/" + (index + 1));
    //         }}
    //       />
    //     ) : null
    //   ) : null
    // );
  }, [nfts, search]);

  const getSkeleton = useMemo(() => {
    const cards = [];
    for (let i = 0; i < 6; i++) {
      cards.push(<NFTCardSkeleton />);
    }

    return cards;
  }, []);

  return (
    <>
      <Section
        grdYTop
        grdYBottom
        vector
        sx={{
          display: "flex",
          flexDirection: "column",

          "& .MuiContainer-root": {
            my: "auto"
          }
        }}
      >
        <Toolbar sx={{ height: "64px" }} />
        <OutlinedInput
          autoFocus
          size="small"
          placeholder={isLoading ? "Search" : "Search #1/ #" + totalSupplyCount}
          startAdornment={
            <InputAdornment>
              <img src={searchImg} alt="" />
            </InputAdornment>
          }
          type="number"
          inputProps={{ min: 1 }}
          onChange={e => setSearch(e.target.value)}
          sx={{ mb: { xs: 6, sm: 10 }, width: { xs: "100%", sm: "320px" } }}
        />
        <InfiniteScroll
          dataLength={nfts.length} //This is important field to render the next data
          next={fetchNext}
          hasMore={nfts.length < totalSupplyCount}
        >
          <Box
            display="grid"
            gridTemplateColumns={{
              xs: "1fr",
              sm: "1fr 1fr 1fr",
              md: "1fr 1fr 1fr 1fr"
            }}
            rowGap={{ xs: 3, sm: 4, md: 5 }}
            columnGap={3}
          >
            {isLoading ? getSkeleton : getList}
          </Box>
        </InfiniteScroll>
      </Section>

      <Drawer
        open={!!openModal}
        data={get(openModal, "data")}
        id={get(openModal, "id")}
        onClose={() => {
          setOpenModal();
          window.history.replaceState(null, "NFTgen2", "/nfts");
        }}
      />
    </>
  );
};

export default Explore;
