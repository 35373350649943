import { Skeleton, styled } from "@mui/material";
import React, { useState } from "react";

const TicketStyle = styled("div")`
  background: white;
  border-radius: 10px;
`;

const TopStyle = styled("div")`
  border-bottom: 1px dashed black;
  padding: 16px 16px 20px 16px;
  position: relative;

  &:before,
  &:after {
    content: "";
    width: 32px;
    height: 36px;
    border-radius: 50%;
    position: absolute;
    bottom: -18px;
    background: black;
  }
  &:before {
    left: -16px;
  }
  &:after {
    right: -16px;
  }
`;

const BottomStyle = styled("div")`
  padding: 24px;
`;

const FlipStyle = styled("div")`
  cursor: pointer;
  background: #ffffff;
  perspective: 1000px;

  &.flip .inner {
    transform: rotateY(180deg);
  }

  .inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    background: #f1f3f5;
  }
`;

const NFTTicketSkeleton = ({ id, title, caption, img, status }) => {
  const [flip, setFlip] = useState(false);

  return (
    <TicketStyle>
      <TopStyle>
        <FlipStyle
          className={`${flip ? "flip" : ""} ratio1-1`}
          onClick={() => {
            setFlip(!flip);
          }}
        >
          <div className="inner">
            <div className="ratio1-1">
              <Skeleton
                animation="wave"
                height="100%"
                width="100%"
                sx={{ transform: "none" }}
              />
            </div>
          </div>
        </FlipStyle>

        <Skeleton
          variant="text"
          animation="wave"
          width="70%"
          sx={{ fontSize: "1.25rem", mt: 2, mx: "auto" }}
        />
      </TopStyle>
      <BottomStyle>
        <Skeleton
          variant="text"
          animation="wave"
          width="100%"
          sx={{ fontSize: "0.875rem", mb: "6px" }}
        />
        <Skeleton
          variant="text"
          animation="wave"
          width="60%"
          sx={{ fontSize: "0.875rem" }}
        />
      </BottomStyle>
    </TicketStyle>
  );
};

export default NFTTicketSkeleton;
