import { CloseRounded } from "@mui/icons-material";
import {
  Drawer as MuiDrawer,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import get from "lodash.get";
import numeral from "numeral";
import React, { useMemo } from "react";
import NFTTicket from "./NFTTicket";
import NFTTicketSkeleton from "./NFTTicketSkeleton";
import StatusCard from "./StatusCard";

const getByTrait = (data = {}, traitname) => {
  const input = get(data, "attributes", []);
  const found = input.find
    ? input.find((x) => x.trait_type === traitname)
    : undefined;
  return found ? get(found, "value") : undefined;
};

const DrawerStyle = styled(MuiDrawer)`
  & > .MuiBackdrop-root {
    background: transparent;
  }

  & > .MuiPaper-root {
    background: transparent;
    padding-top: 80px;
    margin-left: 24px;
    width: 380px;
    max-width: calc(100% - 24px);
  }
  ∏ ${({ theme }) => theme.breakpoints.up("md")} {
    & > .MuiPaper-root {
      padding-top: 152px;
      margin-left: 24px;
    }
  }
`;

const BoxStyle = styled("div")`
  background: #000;
  border-radius: 40px 0 0 40px;
  box-shadow: -10px 4px 8px 2px rgba(0, 0, 0, 0.35);
  padding: 24px;
  position: relative;

  > .MuiIconButton-root {
    color: inherit;
    position: absolute;
    top: 16px;
    right: 16px;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding: 32px;
  }
`;

const Drawer = ({ open, onClose, data, id }) => {
  const isLoading = useMemo(() => {
    return !data;
  }, [data]);
  const missionComplete = useMemo(() => {
    return parseInt(getByTrait(data, "Missions Completed") || 0, 10);
  }, [data]);

  return (
    <DrawerStyle open={open} anchor="right" onClose={onClose}>
      <BoxStyle>
        <IconButton onClick={onClose}>
          <CloseRounded />
        </IconButton>

        {isLoading ? (
          <NFTTicketSkeleton />
        ) : (
          <NFTTicket
            id={id}
            title={get(data, "name")}
            caption={get(data, "description")}
            img={get(data, "image")}
            attributes={data.attributes}
            status={[
              missionComplete > 0,
              missionComplete > 1,
              missionComplete > 2,
            ]}
          />
        )}

        <Typography
          textTransform="uppercase"
          variant="body2"
          align="center"
          fontWeight={600}
          my={3}
          sx={{ color: "#D9D9D9" }}
        >
          Current Status
        </Typography>

        <StatusCard
          points={numeral(getByTrait(data, "Points") || 0).format("0,0")}
          status={{
            checkIn: getByTrait(data, "Checked In") !== "No",
            redeemed: getByTrait(data, "Redeemed") !== "No",
            bonus1: getByTrait(data, "Bonus 1") !== "No",
            bonus2: getByTrait(data, "Bonus 2") !== "No",
            // tShirt: true,
            // bottle: false,
            // cap: true,
            // opener: false,
          }}
        />
      </BoxStyle>
    </DrawerStyle>
  );
};

export default Drawer;
