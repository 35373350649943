import {
  CheckCircleOutlineRounded,
  CheckCircleRounded,
  CloseRounded,
} from "@mui/icons-material";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  Snackbar,
  styled,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { isBrowser } from "react-device-detect";
import Confetti from "react-dom-confetti";
import { QrReader } from "react-qr-reader";
import Section from "../components/Section";
import Kaikas from "../images/kaikas.png";

const White = styled("span")`
  color: white !important;
`;

const KaikasIcon = styled("img")`
  width: 75px;
  margin-right: 6px;
`;

const BackButton = styled(IconButton)`
  position: absolute;
  top: 24px;
  right: 24px;
  color: white;
  background: rgba(255, 255, 255, 0.2);
`;

const QrStatus = styled("div")`
  padding: 24px;
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: calc(100% - 48px);
  border-radius: 1rem;
  z-index: 1;
  background: rgba(255, 255, 255, 0.2);
`;

const ColorCircularProgress = styled(CircularProgress)`
  && {
    z-index: 2000;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    & .MuiCircularProgress-svg {
      color: #ffffff !important;
    }
    & .MuiCircularProgress-circle {
      color: #ffffff !important;
    }
  }
`;

const Loading = () => {
  return <ColorCircularProgress size={40} thickness={5} />;
};

const CameraIcon = styled(CameraAltOutlinedIcon)`
  margin-left: 8px;
`;

const CameraContainer = styled(Box)`
  width: 100%;
  height: 100%;
  background: black;
  display: flex;

  > section {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
`;

const Camera = styled(Box)`
  width: 100%;
  height: 100%;
  background: black;
  flex-grow: 1;
  border-radius: 1rem;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding: 64px 24px;
  }
`;

const AllowBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalBg = styled(AllowBox)`
  background: rgba(0, 0, 0, 0.8);
  padding: 24px 24px;
  border-radius: 20px;
  z-index: 3000;
`;

const Word = styled(Typography)`
  padding: 8px 16px;
  background: #1c1c1c;
  display: flex;
  border-radius: 8px;

  span {
    display: block;
    width: 24px;
  }
`;

const CheckIcon = ({ checked, className }) => {
  return checked ? (
    <CheckCircleRounded color="primary" className={className} />
  ) : (
    <CheckCircleOutlineRounded sx={{ opacity: "0.5" }} className={className} />
  );
};

const isBackText = (text = "") => {
  const isBack = text.includes(":");
  return isBack;
};

const isUUID = (text = "") => {
  return !!text.match(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  );
};

const getAddress = (text = "") => {
  if (!isBackText(text)) return false;
  const textSplit = text.split(":");
  return textSplit[0];
};
const getUUID = (text = "") => {
  if (!isBackText(text)) return false;
  const textSplit = text.split(":");
  const lastPart = textSplit[textSplit.length - 1];
  if (!isUUID(lastPart)) return false;
  return lastPart;
};

const KaikasImage = () => {
  if (isBrowser)
    return (
      <Link
        href="https://medium.com/fingerlabs/en-how-to-set-up-kaikas-wallet-d68107865e25"
        target="_blank"
        className="ml-2"
      >
        <KaikasIcon src={Kaikas} />
      </Link>
    );
  return (
    <Link
      href="https://kaikas.zendesk.com/hc/en-us/articles/10915901333913-How-do-I-install-Mobile-Kaikas-"
      target="_blank"
      className="ml-2"
    >
      <KaikasIcon src={Kaikas} />
    </Link>
  );
};

const AccessNFT = () => {
  const [words, setWords] = useState([]);
  const [showWords, setShowWords] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const [isScannedFront, setIsScannedFront] = useState(false);
  const [isScannedBack, setIsScannedBack] = useState(false);
  const [frontValue, setFrontValue] = useState();
  const [backValue, setBackValue] = useState();
  const [backValueAddress, setBackValueAddress] = useState();
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [isShowSnack, setIsShowSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState(false);

  const onCloseCamera = useCallback(() => {
    setShowCamera(false);
  }, []);

  const onCloseError = useCallback(() => {
    setShowError(false);
  }, []);

  const onScanQR = useCallback((result) => {
    if (!!result) {
      const text = result?.text;
      if (isBackText(text)) {
        const uuid = getUUID(text);
        const address = getAddress(text);
        if (uuid) {
          setIsShowSnack(true);
          setSnackMessage("Back card scanned");
          setBackValue(uuid);
          setBackValueAddress(address);
          setIsScannedBack(true);
          try {
            navigator.vibrate(200);
          } catch {}
        }
      } else {
        setIsShowSnack(true);
        setSnackMessage("Front card scanned");
        setFrontValue(text);
        setIsScannedFront(true);
        try {
          navigator.vibrate(200);
        } catch {}
      }
    }
  }, []);

  const clear = useCallback(() => {
    setIsApiLoading(false);
    setShowCamera(false);
    setIsScannedFront(false);
    setIsScannedBack(false);
    setFrontValue(undefined);
    setBackValue(undefined);
    setBackValueAddress(undefined);
    setIsShowSnack(false);
    setSnackMessage(undefined);
  }, []);

  const onExitError = useCallback(() => {
    clear();
    setShowError(false);
  }, [clear]);

  const handleClose = useCallback(() => {
    setIsShowSnack(false);
  }, []);

  useEffect(() => {
    const main = async () => {
      if (isScannedFront && isScannedBack && isApiLoading === false) {
        setIsApiLoading(true);
        try {
          const response = await axios.post(
            process.env.REACT_APP_SEED_API_URL,
            {
              frontMnemonic: frontValue,
              uuid: backValue,
              address: backValueAddress,
            }
          );
          const responseData = response?.data;
          const mnemonic = responseData?.data?.mnemonic;
          const statusCode = responseData?.statusCode;
          if (statusCode === "EXPO:0002" && mnemonic.split(" ").length === 12) {
            setWords(mnemonic.split(" "));
            setShowWords(true);
            clear();
          } else {
            clear();
            setShowError(true);
          }
        } catch (error) {
          clear();
          setShowError(true);
        }
      }
    };
    main();
  }, [
    isScannedFront,
    isScannedBack,
    isApiLoading,
    frontValue,
    backValue,
    backValueAddress,
    clear,
  ]);

  return (
    <>
      <Section
        grdYTop
        grdYBottom
        vector
        fullScreen
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "relative",

          "& .MuiContainer-root": {
            my: "auto",
            flexGrow: 1,
            py: 3,
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        {showWords ? (
          <Box mt={6}>
            <Typography align="center" variant="h3" mb={1}>
              Seed Phrase
            </Typography>
            <Typography align="center" whiteSpace="pre-line">
              <strong>Warning: </strong>A dApp will never ask for your seed
              phrase. Giving away your seed phrase may result in the loss of
              your assets.
            </Typography>

            <Typography variant="body2" align="center" className="mt-3">
              Write down or save your seed phrase and import it on to your
              Kaikas Wallet to access your NFT.
            </Typography>
            <Divider
              sx={{ background: "rgba(255,255,255,0.3)", my: { xs: 4, md: 6 } }}
            />

            <Box
              display="grid"
              gridTemplateColumns={{ xs: "1fr 1fr", sm: "1fr 1fr 1fr 1fr" }}
              gap={3}
            >
              {words.map((k, idx) => (
                <Word key={k}>
                  <span>{idx + 1}</span>
                  {k}
                </Word>
              ))}
            </Box>

            <Divider
              sx={{ background: "rgba(255,255,255,0.3)", my: { xs: 4, md: 6 } }}
            />

            <Typography
              variant="body2"
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "initial", sm: "center" }}
            >
              <strong className="pr-3">
                How to setup <KaikasImage />:{" "}
              </strong>

              <Box className="d-flex" pt={{ xs: 1, sm: 0 }}>
                <Link
                  href="https://medium.com/fingerlabs/en-how-to-set-up-kaikas-wallet-d68107865e25"
                  target="_blank"
                  className="pr-3 bd-r"
                >
                  Website Setup
                </Link>
                <Link
                  href="https://kaikas.zendesk.com/hc/en-us/articles/10915901333913-How-do-I-install-Mobile-Kaikas-"
                  target="_blank"
                  className="px-3"
                >
                  Mobile Setup
                </Link>
              </Box>
            </Typography>
          </Box>
        ) : (
          <Camera>
            <div className="my-auto d-flex flex-column">
              <Typography variant="h4" align="center" mb={1}>
                Scan both side of the card to reveal seed phrase.
              </Typography>
              {/* <CameraContainer>
                <QrReader
                  constraints={{ facingMode: "environment" }}
                  delay={500}
                  onError={() => {}}
                  onScan={() => {}}
                  // chooseDeviceId={()=>selected}
                  style={{ width: "200px", heigth: "200px" }}
                />
              </CameraContainer>*/}
              <Button
                variant="contained"
                sx={{ mt: 3, px: 2, maxWidth: "320px", mx: "auto" }}
                onClick={() => {
                  setShowCamera(true);
                }}
              >
                Open the camera
                <CameraIcon sx={{ fontSize: "20px" }} />
              </Button>
            </div>

            <div className="bd-t pt-6">
              <Typography mb={1} fontWeight="bold">
                Access your wallet
              </Typography>
              <Typography mb={1} variant="body2">
                Scan both QR codes below to retrieve your seed phrase wallet
                (Kaikas Wallet) containing 12 mnemonic words.
              </Typography>
              {/* <Typography variant="body2">
                2. Write down or save your seed phrase and import it on to your
                Kaikas Wallet to access your NFT.
              </Typography>*/}
            </div>
          </Camera>
        )}
      </Section>

      <Modal
        open={showCamera}
        onClose={onCloseCamera}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CameraContainer>
          {isApiLoading && <Loading />}

          <QrStatus>
            <Typography fontWeight="bold" mb={1.5}>
              Scan QR code
            </Typography>

            <Typography
              variant="body2"
              mb={1}
              display="flex"
              alignItems="cenetr"
            >
              <CheckIcon checked={isScannedFront} className="mr-2" />
              Front card
            </Typography>

            <Typography variant="body2" display="flex" alignItems="cenetr">
              <CheckIcon checked={isScannedBack} className="mr-2" />
              Back card
            </Typography>
          </QrStatus>

          <QrReader
            constraints={{ facingMode: "environment" }}
            delay={100}
            onResult={onScanQR}
            style={{ width: "100%", heigth: "100%" }}
          />

          <BackButton disabled={isApiLoading} onClick={onCloseCamera}>
            <CloseRounded />
          </BackButton>
        </CameraContainer>
      </Modal>

      <Modal
        open={showError}
        onClose={onCloseError}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalBg>
          <WarningAmberRoundedIcon sx={{ fontSize: "80px", mb: 2 }} />
          <Typography variant="h4" align="center" mb={1} whiteSpace="nowrap">
            Invalid QR code
          </Typography>
          <Typography align="center">Please try again</Typography>
          <Button
            variant="contained"
            sx={{ width: "160px", mt: 3 }}
            onClick={onExitError}
          >
            OK
          </Button>
        </ModalBg>
      </Modal>
      <Confetti active={showWords} />
      <Snackbar
        severity="success"
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={isShowSnack}
        autoHideDuration={2000}
        onClose={handleClose}
        message={snackMessage}
      />
    </>
  );
};

export default AccessNFT;
