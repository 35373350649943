import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
  typography: {
    fontFamily: ["Outfit", "sans-serif"].join(","),
    fontWeightRegular: 300,
    h2: {
      fontWeight: 500,
      lineHeight: 1.25,
    },
    h3: {
      fontWeight: 600,
      lineHeight: 1.3,
    },
    h4: {
      fontWeight: 600,
    },
    body1: {
      fontSize: "1.25rem",
    },
    body2: {
      fontSize: "1rem",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 970,
      lg: 1110,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#04B1FB",
    },
    secondary: {
      main: "#402FF2",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#FFFFFF",
      // primary: '#414040',
      // secondary: '#666666',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: "0",
          fontWeight: 600,

          input: {
            padding: "8px 12px",
            fontSize: "1rem",
          },

          ".MuiInputAdornment-root": {
            padding: "8px",
            width: "40px",
            height: "40px",
            maxHeight: "initial",
            flexShrink: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",

            img: { width: "20px", display: "block" },
          },

          fieldset: {
            border: "1px solid #fff !important",
            borderRadius: "0",
          },
        },
      },
    },
    MuiDrawer: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          height: "20px",
          fontSize: "10px",
          fontWeight: "600",
        },
        filled: {
          background:
            "linear-gradient(89.85deg, #04B1FB 2.64%, #402FF2 103.58%)",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          minWidth: "160px !important",
          background:
            "linear-gradient(120deg, rgba(0,0,0,0.8), black) !important",
          mixBlendMode: "soft-light",
          borderRadius: "12px !important",
          padding: "0 12px",

          "& .MuiMenuItem-root": {
            fontSize: "0.875rem",
            minHeight: "40px",
            padding: "6px 0",
            fontWeight: 500,

            "&:not(:last-child)": {
              borderBottom: "1px solid rgba(255,255,255,0.5) !important",
            },

            "&.active": { color: "#04B1FB" },
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: " 24px",
          paddingRight: " 24px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: "transparent",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "initial",
        },
        contained: {
          background:
            "linear-gradient(89.85deg, #04B1FB 2.64%, #402FF2 103.58%)",
          color: "white",
          borderRadius: "40px",
          fontWeight: 600,
        },
        sizeMedium: {
          height: "40px",
          fontSize: "1.25rem",
          paddingLeft: "24px",
          paddingRight: "24px",
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
