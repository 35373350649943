import { Box, Container, styled } from "@mui/material";
import React from "react";
import bottom from "../images/grd-bottom.png";
import top from "../images/grd-top.png";
import yBottom from "../images/grd-y-bottom.png";
import yTop from "../images/grd-y-top.png";
import vectorImg from "../images/vector.png";

const SectionStyle = styled(Box)`
  position: relative;
  overflow: hidden;
  min-height: ${({ fullScreen }) => (fullScreen ? "100vh" : "initial")};
  padding: ${({ py }) => (!py ? "64px 0" : "")};

  > img {
    position: absolute;

    &.yTop {
      height: 320px;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &.yBottom {
      width: 100%;
      height: 160px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &.top {
      height: 50%;
      top: -200px;
      left: 50%;
      transform: translateX(-50%);
    }
    &.bottom {
      width: 100%;
      bottom: -20%;
      left: 50%;
      transform: translateX(-50%);
    }
    &.vector {
      height: 100vh;
      right: 0;
      top: 0;
      opacity: 0.5;
    }
  }

  .MuiContainer-root {
    position: relative;
    z-index: 1;
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    .yTop {
      height: 400px !important;
    }
    .yBottom {
      height: 240px !important;
    }
    .top {
      width: 100% !important;
      height: auto !important;
      top: -30% !important;
    }
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: ${({ py }) => (!py ? "120px 0" : "")};

    .yTop {
      height: 480px !important;
    }
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    .top {
      top: -50% !important;
    }
  }
`;

const Section = ({
  children,
  grdYTop = false,
  grdYBottom = false,
  grdTop = false,
  grdBottom = false,
  vector = false,
  fullScreen = false,
  ...props
}) => {
  return (
    <SectionStyle fullScreen={fullScreen} {...props}>
      {grdYTop && <img src={yTop} alt="" className="yTop" />}
      {grdYBottom && <img src={yBottom} alt="" className="yBottom" />}
      {grdTop && <img src={top} alt="" className="top" />}
      {grdBottom && <img src={bottom} alt="" className="bottom" />}
      {vector && <img src={vectorImg} alt="" className="vector" />}

      <Container maxWidth="lg">{children}</Container>
    </SectionStyle>
  );
};

export default Section;
