import MenuIcon from "@mui/icons-material/Menu";
import { Menu, MenuItem, useMediaQuery, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { Container } from "@mui/system";
import React from "react";
import { NavLink } from "react-router-dom";
import sixProtocol from "../images/six-protocol-white.png";

const ElevationScroll = (props) => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 2 : 0,
    sx: {
      transition: "0.2s",
      pr: "0 !important",
      py: trigger ? 1 : mdUp ? 6 : 2,
      background: trigger ? "black" : "transparent",

      img: {
        transition: "0.2s",
        height: trigger ? "32px" : mdUp ? "56px" : "40px",
        width: "auto",
      },
    },
  });
};

const Header = (props) => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const pages = [
    { name: "Home", path: "/" },
    { name: "Explore", path: "/nfts" },
    { name: "Access NFT", path: "/access" },
  ];

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ElevationScroll {...props}>
      <AppBar>
        <Container maxWidth="lg">
          <Toolbar sx={{ p: "0 !important", minHeight: "0 !important" }}>
            <Button
              component={NavLink}
              to="/"
              sx={{
                mr: 5,
                p: 0,
                justifyContent: "flex-start",
                height: "auto",
              }}
            >
              <img src={sixProtocol} alt="SIX Protocol" />
            </Button>

            {mdUp ? (
              <Box flexGrow={1}>
                {pages.map((p) => (
                  <Button
                    key={p.name}
                    component={NavLink}
                    to={p.path}
                    sx={{
                      color: "white",
                      fontWeight: "normal",
                      "&.active": {
                        color: (theme) => theme.palette.primary.main,
                      },
                    }}
                  >
                    {p.name}
                  </Button>
                ))}
              </Box>
            ) : (
              <>
                <IconButton
                  size="large"
                  sx={{ color: "white", ml: "auto" }}
                  onClick={handleClick}
                >
                  <MenuIcon />
                </IconButton>

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {pages.map((p) => (
                    <MenuItem
                      key={p.name}
                      component={NavLink}
                      to={p.path}
                      onClick={handleClose}
                      className="bd-b"
                    >
                      {p.name}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </ElevationScroll>
  );
};

export default Header;
